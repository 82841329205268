// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-progress {
    transition: stroke-dashoffset 0.5s ease-in-out; /* Adjust the duration and easing function as needed */
  }
  
  .circle-base {
    transition: stroke 0.5s ease-in-out; /* Adjust the duration and easing function as needed */
  }`, "",{"version":3,"sources":["webpack://./src/utils/chart/PieChart.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C,EAAE,sDAAsD;EACxG;;EAEA;IACE,mCAAmC,EAAE,sDAAsD;EAC7F","sourcesContent":[".circle-progress {\n    transition: stroke-dashoffset 0.5s ease-in-out; /* Adjust the duration and easing function as needed */\n  }\n  \n  .circle-base {\n    transition: stroke 0.5s ease-in-out; /* Adjust the duration and easing function as needed */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
