// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  cursor: pointer;
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ccc;
}

.pagination li.active {
  background-color: #6B7280;
  color: #63898b;
}


	tr td:nth-child(n+5),
	tr th:nth-child(n+5) {
		
        background-color: #111827;
        color: rgb(139, 168, 190);
	}


	tr td:nth-child(1),
	tr th:nth-child(1) {
		color: rgb(139, 168, 190);
        background-color: #111827;
	}

    tr td:nth-child(2),
	tr th:nth-child(2) {
    color: rgb(139, 168, 190);
        background-color: #111827;
	}

    tr td:nth-child(3),
	tr th:nth-child(3) {
    color: rgb(139, 168, 190);
        background-color: #111827;
	}

    tr td:nth-child(4),
	tr th:nth-child(4) {
    color: rgb(139, 168, 190);
        background-color: #111827;
        
	}
`, "",{"version":3,"sources":["webpack://./src/css/ContactList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;;CAGC;;;QAGO,yBAAyB;QACzB,yBAAyB;CAChC;;;CAGA;;EAEC,yBAAyB;QACnB,yBAAyB;CAChC;;IAEG;;IAEA,yBAAyB;QACrB,yBAAyB;CAChC;;IAEG;;IAEA,yBAAyB;QACrB,yBAAyB;CAChC;;IAEG;;IAEA,yBAAyB;QACrB,yBAAyB;;CAEhC","sourcesContent":[".pagination {\n  display: flex;\n  justify-content: center;\n  list-style: none;\n  padding: 0;\n}\n\n.pagination li {\n  cursor: pointer;\n  margin: 0 5px;\n  padding: 5px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  background-color: #ccc;\n}\n\n.pagination li.active {\n  background-color: #6B7280;\n  color: #63898b;\n}\n\n\n\ttr td:nth-child(n+5),\n\ttr th:nth-child(n+5) {\n\t\t\n        background-color: #111827;\n        color: rgb(139, 168, 190);\n\t}\n\n\n\ttr td:nth-child(1),\n\ttr th:nth-child(1) {\n\t\tcolor: rgb(139, 168, 190);\n        background-color: #111827;\n\t}\n\n    tr td:nth-child(2),\n\ttr th:nth-child(2) {\n    color: rgb(139, 168, 190);\n        background-color: #111827;\n\t}\n\n    tr td:nth-child(3),\n\ttr th:nth-child(3) {\n    color: rgb(139, 168, 190);\n        background-color: #111827;\n\t}\n\n    tr td:nth-child(4),\n\ttr th:nth-child(4) {\n    color: rgb(139, 168, 190);\n        background-color: #111827;\n        \n\t}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
